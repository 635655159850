<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />
     <form @submit.prevent="submit">
      <section
        :style="{
          'background-image': `url('${getBannerImg}')`,
          'background-repeat': 'no-repeat',
          'background-size': '100%',
          'background-position': 'center',
        }"
        class="
          free_listing-banner
          d-flex
          align-items-center
          justify-content-center
          py-5
          position-relative
        "
      >
        <div class="container">
          <div class="banner_content-wrapper">
            <div class="banner_image__picker text-center pointer">
              <input
                type="file"
                name="file-input"
                id="file-input"
                class="
                  file-input__input
                  position-absolute
                  overflow-hidden
                  invisible
                "
                @change="onFilebanner"
                ref="banner"
              />
              <label
                class="
                  file-input__label
                  d-inline-flex
                  align-items-center
                  text-white
                  pointer
                  rounded
                  grey-bg
                  py-2
                  px-3
                  f-18
                  text-dark
                "
                for="file-input"
              >
                <i class="uil-upload f-20 pe-2"></i>
                <span class="f-20 fw-medium">Upload Image</span>
              </label>
            </div>
          </div>
        </div>
      </section>
      <!-- <img v-if="banner_url" :src="banner_url" /> -->

      <section class="listing_page-body py-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-8">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="over-view__wrapper col-12">
                      <h2 class="secondary-color f-24">Business Info</h2>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-Firstname-input">
                          Business Name
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          id="formrow-Firstname-input"
                          v-model="form.title"
                          placeholder="Enter Your First Name"
                        />
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-email-input"
                          >Email</label
                        >
                        <input
                          type="email"
                          class="form-control"
                          id="formrow-email-input"
                          v-model="form.email"
                          placeholder="Enter Your Email Address"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="formrow-Contact-input">
                          Contact No.
                        </label>
                        <input
                          type="tel"
                          class="form-control"
                          id="formrow-Contact-input"
                          placeholder="(907) 555-0101"
                          v-model="form.mobile"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="Website">Website</label>
                        <input
                          type="text"
                          class="form-control"
                          id="Website"
                          placeholder="www.xyz.com"
                          v-model="form.website"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <!-- Rating Select Field -->
                      <div class="mb-3">
                        <label class="form-label" for="formrow-rating-select">Rating</label>
                        <select
                          class="form-select"
                          id="formrow-rating-select"
                          aria-label="select example"
                          v-model="form.rating"
                        >
                          <option disabled :value=null selected>Select Rating (1-10)</option>
                          <option v-for="n in 10" :key="n" :value="n">{{ n }}</option>
                        </select>
                      </div>

                      <!-- Ranking Select Field -->
                      <div class="mb-3">
                        <label class="form-label" for="formrow-ranking-select">Ranking</label>
                        
                        <select
                          class="form-select"
                          id="formrow-ranking-select"
                          aria-label="select example"
                          v-model="form.ranking"
                        >
                          <option disabled :value=null selected>Select Ranking (1-3)</option>
                          <option v-for="n in 3" :key="n" :value="n">{{ n }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="category"
                          >Category</label
                        >
                        <!-- {{ form.category_id }} -->
                        <!-- <select
                          v-model="form.category_id"
                          class="form-select"
                          id="category"
                          aria-label="select example"
                          @change="changeCategory(form.category_id)"
                        >
                          <option disabled v-bind:value="null">
                            Select Category
                          </option>
                          <option
                            v-for="(category, index) in categories"
                            :key="index"
                            :value="category.id"
                          >
                            {{ category.title }}
                          </option>
                        </select> -->
                        <multiselect
                        v-model="categorySelected"
                        :multiple="true"
                        :options="categoryOptions"
                        :preserve-search="true"
                        label="title"
                        track-by="title"
                        @select="changeCategory()"
                        >
                        </multiselect>
                      </div>
                    </div>
                    <!-- <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="sub_category"
                          >Sub Category</label
                        >
                        {{ form.sub_category_id }}
                        <select
                          v-model="form.sub_category_id"
                          class="form-select"
                          id="sub_category"
                          aria-label="select example"
                        >
                          <option :value="null">Select Sub Category</option>
                          <option
                            v-for="(subCategory, index) in subCategories"
                            :key="index"
                            :value="subCategory.id"
                          >
                            {{ subCategory.title }}
                          </option>
                        </select>
                        <multiselect
                          v-model="selected"
                          :multiple="true"
                          :options="options"
                          :preserve-search="true"
                          label="title"
                          track-by="title"
                        >
                        </multiselect>
                      </div>
                    </div> -->
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="form-label" for="overview"
                          >overview</label
                        >
                        <textarea
                          type="text"
                          class="form-control"
                          id="overview"
                          placeholder="www.xyz.com"
                          v-model="form.overview"
                        />
                      </div>
                    </div>
                    <!-- <div class="features_wrapper pb-4 col-12">
                      <h2 class="secondary-color f-20">Business Features</h2>
                      <div class="features_inner-wrapper d-flex flex-wrap">
                        <div
                          v-for="(item, index) in allFeature"
                          :key="index"
                          class="form-check features_inner pb-1 col-6 col-lg-4"
                        >
                          <input
                            class="form-check-input"
                            type="checkbox"
                            :value="item.id"
                            v-model="feature"
                            :id="'flexCheckChecked1' + index"
                          />
                          <label
                            class="features-label form-check-label f-14 pointer"
                            :for="'flexCheckChecked1' + index"
                            >{{ item.feature }}</label
                          >
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <!-- <input
                      @change="uploadLogo"
                      accept="image/*"
                      type="file"
                      id="logo"
                      class="file-input__input position-absolute overflow-hidden invisible"
                    />
                    <label
                      class="file-input__label d-inline-flex align-items-center text-white pointer rounded grey-bg py-2 px-3 f-18 text-dark"
                      for="logo"
                    >
                      <i class="uil-upload f-20 pe-2"></i>
                      <span class="f-20 fw-medium">Upload Image</span>
                  </label>-->
                  <!--------------------- Select user---------->
                  <div class="row">
                    <div class="over-view__wrapper col-12">
                      <h2 class="secondary-color f-24">Select User</h2>
                    </div>
                    <div class="col-md-12 col-12">
                      <div class="mb-3">
                        <label for="users" class="form-label">User</label>
                        <select
                          disabled
                          v-model="form.user_id"
                          id="users"
                          aria-label="select example"
                          class="form-select"
                        >
                          <option disabled :value="null">
                            Please Select an User
                          </option>
                          <option
                            v-for="(user, index) in users"
                            :key="index"
                            :value="user.id"
                          >
                            {{ user.full_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!--------------------- Select user---------->
                  <div class="row mb-2">
                    <div class="over-view__wrapper col-12">
                      <h2 class="secondary-color f-24">Social Profiles</h2>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label mb-0" for="facebook"
                        >Facebook</label
                      >
                      <div class="input-group flex-nowrap my-2">
                        <input
                          type="text"
                          placeholder="Facebook"
                          class="form-control"
                          v-model="form.social.facebook"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label mb-0" for="instagram"
                        >Instagram</label
                      >
                      <div class="input-group flex-nowrap my-2">
                        <input
                          type="text"
                          placeholder="Instagram"
                          aria-label="Instagram"
                          aria-describedby="addon-wrapping"
                          v-model="form.social.instagram"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label mb-0" for="twitter"
                        >Twitter</label
                      >
                      <div class="input-group flex-nowrap my-2">
                        <input
                          type="text"
                          placeholder="Twitter"
                          aria-label="Twitter"
                          aria-describedby="addon-wrapping"
                          class="form-control"
                          v-model="form.social.twitter"
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label mb-0" for="google">Google</label>
                      <div class="input-group flex-nowrap my-2">
                        <input
                          type="text"
                          placeholder="Google"
                          aria-label="Google"
                          aria-describedby="addon-wrapping"
                          class="form-control"
                          v-model="form.social.google"
                        />
                      </div>
                    </div>
                  </div>
                  <!--------------------- free listing Page Gallery---------->
                  <div class="features_gallery--wrapper">
                    <h2 class="secondary-color f-24">Photo Gallery</h2>
                    <div class="images_wrapper-features-gallery row">
                      <div class="col-md-6">
                        <label for="media" class="f-14 fw-medium text-dark"
                          >Listing Photos*</label
                        >
                        <!-- <UploadMedia :multiple="true" :required="false" @media="Media" /> -->
                        <!-- <UploadImages @changed="handleImages" /> -->
                        <!-- <UpdateMedia
                          :multiple="true"
                          :required="false"
                          @addedMedia="addMedia"
                          server="http://127.0.0.1:8000/api/upload"
                          mediaFilePath="http://127.0.0.1:8000/storage/listing_images"
                          mediaServer
                          @changed="handleImage"
                        ></UpdateMedia> -->
                        <vue-upload-multiple-image
                          @upload-success="uploadImageSuccess"
                          @before-remove="beforeRemove"
                          :data-images="images"
                          :maxImage="10"
                          :showEdit="false"
                          dragText="upload Images"
                          browseText="Not Image Found"
                        ></vue-upload-multiple-image>
                        <!--------------------- free listing Page Video---------->
                        <div class="gallery_video-wrapper mt-3">
                           <label for="media" class="f-14 fw-medium text-dark"
                          >Listing Videos*(Max Size 50MB)</label
                        >
                          <div
                            class="
                              video-file-input
                              position-relative
                              text-center
                              rounded
                              pointer
                            "
                          >
                          
                          <span class="text-danger" v-if="videoError">Video Size Too Much(Max 50MB)</span>
                            <input
                              @change="uploadVideo"
                              accept="video/*"
                              type="file"
                              name="file-input"
                              id="file-input-video"
                              class="file-input__input"
                            />
                            <label
                              for="file-input-video"
                              class="
                                file-input__label
                                d-inline-flex
                                align-items-center
                                text-white
                                pointer
                                rounded
                                grey-bg
                                py-2
                                px-3
                                f-18
                                text-dark
                              "
                            >
                              <i class="uil-upload f-20 pe-2"></i>
                              <span class="f-20 fw-medium">Upload Video</span>
                            </label>
                          </div>
                        </div>
                        <div
                          class="features_gallery--wrapper"
                          v-if="this.video_url != null"
                        >
                          <div
                            class="
                              videos-show_main
                              d-flex
                              flex-wrap
                              grey-bg
                              my-4
                              p-md-4
                              rounded
                            "
                          >
                            <div
                              v-for="(item, index) in video_url"
                              :key="index"
                              class="
                                videos_wrapper-inner
                                col-md-6 col-12
                                position-relative
                              "
                            >
                              <video
                                :src="item.media_path"
                                width="100%"
                                class="video_styles rounded p-2"
                                height="100%"
                                controls
                              ></video>
                              <span
                                @click="removeVideo(index, item.id)"
                                class="video_del-icon pointer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 40 40"
                                  width="16px"
                                  height="16px"
                                >
                                  <path
                                    fill="#f78f8f"
                                    d="M20,38.5C9.799,38.5,1.5,30.201,1.5,20S9.799,1.5,20,1.5S38.5,9.799,38.5,20S30.201,38.5,20,38.5z"
                                  />
                                  <path
                                    fill="#c74343"
                                    d="M20,2c9.925,0,18,8.075,18,18s-8.075,18-18,18S2,29.925,2,20S10.075,2,20,2 M20,1 C9.507,1,1,9.507,1,20s8.507,19,19,19s19-8.507,19-19S30.493,1,20,1L20,1z"
                                  />
                                  <path
                                    fill="#fff"
                                    d="M18.5 10H21.5V30H18.5z"
                                    transform="rotate(-134.999 20 20)"
                                  />
                                  <path
                                    fill="#fff"
                                    d="M18.5 10H21.5V30H18.5z"
                                    transform="rotate(-45.001 20 20)"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--------------------- free listing Faq---------->
                  <div class="listing__faq py-4">
                    <div class="faq_inner-listing">
                      <h2 class="secondary-color py-2 f-24">
                        Frequently Asked Questions
                      </h2>
                      <div style="padding-bottom: 10px" class="faq_box-wrapper">
                        <div
                          style="margin-bottom: 10px"
                          v-for="(item, index) in faq"
                          :key="index"
                          class="
                            faq_form-box-inner
                            border border-1 border-secondary
                            rounded
                            p-3
                          "
                        >
                          <!-- <form class="listing_faq-form"> -->
                          <input
                            type="text"
                            name="listing_faq-question"
                            id="listing_faq-question"
                            placeholder="Question"
                            class="w-100 py-2 form-control rounded-0"
                            v-model="item.question"
                          />
                          <div class="input-group">
                            <textarea
                              class="form-control mt-3 w-100 rounded-0"
                              aria-label="With textarea"
                              rows="4"
                              cols="50"
                              id="listing_overview"
                              placeholder="Answer"
                              v-model="item.answer"
                            ></textarea>
                          </div>
                          <!-- <div class="list_add-faq pt-4">
                            <div
                              class="
                                list_add-faq-inner
                                p-3
                                rounded
                                grey-bg
                                pointer
                              "
                            >
                              <h3 class="text-dark f-16 fw-semibold m-0">
                                <i
                                  class="
                                    fa fa-plus fa-solid fa-sharp
                                    text-dark
                                    pe-2
                                    f-20
                                  "
                                ></i
                                >Add Another
                              </h3>
                            </div>
                          </div> -->
                          <div class="py-2"> <a
                            class="btn btn-danger"
                            v-show="index != 0"
                            @click="removeFaqBox(index)"
                          >
                            Remove
                          </a></div>
                        </div>
                      </div>
                      <a @click="addMore()" class="btn btn-primary">
                        Add Another
                      </a>
                    </div>
                  </div>
                  <div class="dish_inner-listing py-4">
                    <h2 class="secondary-color py-2 f-24">Add Menu Details</h2>

                    <div style="padding-bottom: 10px" class="dish_box-wrapper">
                      <div
                        style="margin-bottom: 10px"
                        v-for="(dish, index) in getDishes"
                        :key="`dishitem-${index}`"
                        class="dish_form-box-inner border border-1 border-secondary rounded p-3"
                      >
                        <!-- Dish Image Upload -->
                        <div class="form-group mb-3">
                          <label for="dish_image" class="form-label">Dish Image</label>
                          <input
                            type="file"
                            class="form-control"
                            id="dish_image"
                            @change="onImageChange($event, index)"
                          />
                          <img
                            v-show="dish.previewImage"
                            :key="`dish-image-${index}`"
                            :src="dish.previewImage"
                            alt="Dish Image Preview"
                            class="img-fluid mt-2"
                            :id="`dish-${index}`"
                            style="max-height: 150px;"
                          />
                        </div>

                        <!-- Dish Name -->
                        <div class="row">
                            <div class="col-md-6 mb-3">
                              <input
                                type="text"
                                name="dish_name"
                                id="dish_name"
                                placeholder="Dish Name"
                                class="w-100 py-2 form-control rounded-0"
                                v-model="dish.name"
                              />
                            </div>

                            <div class="col-md-6 mb-3">
                              <input
                                type="text"
                                name="dish_name"
                                id="dish_name"
                                placeholder="Dish Price"
                                class="w-100 py-2 form-control rounded-0"
                                v-model="dish.price"
                              />
                            </div>

                            <div class="col-md-6 mb-3">
                              <input
                                type="text"
                                name="dish_type"
                                id="dish_type"
                                placeholder="Dish Type"
                                class="w-100 py-2 form-control rounded-0"
                                v-model="dish.type"
                              />
                            </div>
                          </div>

                        <!-- Remove Button -->
                        <div class="py-2">
                          <a
                            class="btn btn-danger"
                            v-show="index != 0"
                            @click="removeDishBox(index,dish.id)"
                          >
                            Remove
                          </a>
                        </div>
                      </div>
                    </div>
                    <a @click="addMoreDish()" class="btn btn-primary">Menu +</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="card">
                <div class="card-body">
                  <!-- <form class="side-bar_listing-form"> -->
                  <!--------------------- free listing Sidemenu Location---------->
                  <div class="listings-location_main rounded">
                    <div class="location_inner-wrappper rounded">
                      <div class="location_info-wrapper">
                        <div class="row">
                          <div class="col-md-12 mb-3">
                            <AddressFields
                              :modelValue="address"
                              @update:modelValue="address = $event"
                            />
                            <!-- <label class="form-label" for="street address">
                                Street
                                Address
                            </label>-->
                            <!-- <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Street Address"
                                  aria-label="Street Address"
                                  v-model="form.address"
                                  aria-describedby="addon-wrapping"
                                />
                            </div>-->
                          </div>
                        </div>
                        <div class="row">
                          <!-- <div class="col-md-12">
                              <label class="form-label" for="City">City</label>
                          </div>-->
                          <!-- <div class="col-md-12 mb-3">
                              <div class="listing-state">
                                <input
                                  type="text"
                                  name="location_listing-city"
                                  placeholder="Business Email"
                                  class="form-control w-100"
                                  v-model="form.city"
                                />
                              </div>
                          </div>-->
                          <!-- <div class="col-md-12">
                              <label class="form-label" for="State">State</label>
                          </div>-->
                          <!-- <div class="col-md-12 mb-3">
                              <div class="listing-state">
                                <input
                                  type="text"
                                  name="location_listing-city"
                                  placeholder="Business Email"
                                  v-model="form.state"
                                  class="form-control w-100"
                                />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <label class="form-label" for="County">County</label>
                            </div>
                            <div class="col-md-12 mb-3">
                              <div class="listing-state">
                                <input
                                  type="text"
                                  name="location_listing-city"
                                  placeholder="Business Email"
                                  class="form-control w-100"
                                  v-model="form.country"
                                />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <label class="form-label" for="Zip Code">Zip Code</label>
                            </div>
                            <div class="col-md-12 mb-3">
                              <div class="listing-state">
                                <input
                                  type="text"
                                  name="location_listing-city"
                                  placeholder="Business Email"
                                  class="form-control w-100"
                                  v-model="form.zip_code"
                                />
                              </div>
                          </div>-->
                          <div class="col-md-3 mb-3">
                            <div class="listing-state">
                              <!-- <select
                                  name="listings_states"
                                  id="cars"
                                  class="form-select w-100 form-control"
                                >
                                  <option value="volvo">TX</option>
                                  <option value="saab">CA</option>
                                  <option value="opel">OP</option>
                                  <option value="audi">CT</option>
                              </select>-->
                            </div>
                          </div>
                          <div class="col-md-3 mb-3">
                            <div class="listing-country">
                              <!-- <select
                                  name="listings_country"
                                  id="cars"
                                  class="form-select w-100 form-control"
                                >
                                  <option value="volvo">US</option>
                                  <option value="saab">AU</option>
                                  <option value="opel">TI</option>
                              </select>-->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--------------------- free listing Sidemenu HOurs---------->
                  <div class="listings-hours_main my-3">
                    <div class="location_listing-hours-wrapper rounded">
                      <label
                            class="features-label form-check-label f-14 pointer pe-3"
                            for="availibilityHours"
                            >Availibility Hours</label
                          >
                      <input
                      @click="hoursStatus"
                            class="form-check-input"
                            v-model="form.availability_hours"
                            type="checkbox"
                            id="availibilityHours"
                          />
                      <div
                        class="
                          hours-header
                          row
                          justify-content-between
                          align-items-center
                          py-2
                        "
                      >
                        <div v-if="form.availability_hours" class="col-4">
                          <h2 class="text-dark f-16 fw-bold">Hours</h2>
                        </div>
                      </div>
                      <div v-if="form.availability_hours" class="hours-listing-body">
                        <div
                          class="hours-body-inner row align-items-center my-2"
                        >
                          <div
                            class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              f-16
                              text-dark
                            "
                          >
                            <span>Monday</span>
                          </div>

                          <div class="col-6 col-md-4">
                            <input
                              class="form-control pointer p-1"
                              id="monday-start-time"
                              type="time"
                              name="start-time"
                              v-model="time.mondayFrom"
                              value="00:00"
                            />
                          </div>
                          <div class="col-6 col-md-4">
                            <input
                              class="form-control pointer p-1"
                              id="appt-end-time"
                              type="time"
                              v-model="time.mondayTo"
                              name="end-time"
                              value="00:00"
                            />
                          </div>
                        </div>
                        <div
                          class="hours-body-inner row align-items-center my-2"
                        >
                          <div
                            class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              f-16
                              text-dark
                            "
                          >
                            <span>Tuesday</span>
                          </div>
                          <div class="col-6 col-md-4">
                            <input
                              class="form-control pointer p-1"
                              id="appt-start-time"
                              type="time"
                              name="start-time"
                              v-model="time.tuesdayFrom"
                              value="00:00"
                            />
                          </div>
                          <div class="col-6 col-md-4">
                            <input
                              class="form-control pointer p-1"
                              id="appt-end-time"
                              type="time"
                              name="end-time"
                              v-model="time.tuesdayTo"
                              value="00:00"
                            />
                          </div>
                        </div>
                        <div
                          class="hours-body-inner row align-items-center my-2"
                        >
                          <div
                            class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              f-16
                              text-dark
                            "
                          >
                            <span>Wednesday</span>
                          </div>
                          <div class="col-6 col-md-4">
                            <input
                              class="form-control pointer p-1"
                              id="appt-start-time"
                              type="time"
                              name="start-time"
                              v-model="time.wednesdayFrom"
                              value="00:00"
                            />
                          </div>
                          <div class="col-6 col-md-4">
                            <input
                              class="form-control pointer p-1"
                              id="appt-end-time"
                              type="time"
                              v-model="time.wednesdayTo"
                              name="end-time"
                              value="00:00"
                            />
                          </div>
                        </div>
                        <div
                          class="hours-body-inner row align-items-center my-2"
                        >
                          <div
                            class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              f-16
                              text-dark
                            "
                          >
                            <span>thursday</span>
                          </div>
                          <div class="col-6 col-md-4">
                            <input
                              class="form-control pointer p-1"
                              id="appt-start-time"
                              type="time"
                              v-model="time.thursdayFrom"
                              name="start-time"
                              value="00:00"
                            />
                          </div>
                          <div class="col-6 col-md-4">
                            <input
                              class="form-control pointer p-1"
                              id="appt-end-time"
                              type="time"
                              name="end-time"
                              v-model="time.thursdayTo"
                              value="00:00"
                            />
                          </div>
                        </div>
                        <div
                          class="hours-body-inner row align-items-center my-2"
                        >
                          <div
                            class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              f-16
                              text-dark
                            "
                          >
                            <span>Friday</span>
                          </div>
                          <div class="col-6 col-md-4">
                            <input
                              class="form-control pointer p-1"
                              id="appt-start-time"
                              type="time"
                              v-model="time.fridayFrom"
                              name="start-time"
                              value="00:00"
                            />
                          </div>
                          <div class="col-6 col-md-4">
                            <input
                              class="form-control pointer p-1"
                              id="appt-end-time"
                              type="time"
                              v-model="time.fridayTo"
                              name="end-time"
                              value="00:00"
                            />
                          </div>
                        </div>
                        <div
                          class="hours-body-inner row align-items-center my-2"
                        >
                          <div
                            class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              f-16
                              text-dark
                            "
                          >
                            <span>Saturday</span>
                          </div>
                          <div class="col-6 col-md-4">
                            <input
                              class="form-control pointer p-1"
                              id="appt-start-time"
                              type="time"
                              v-model="time.saturdayFrom"
                              name="start-time"
                              value="00:00"
                            />
                          </div>
                          <div class="col-6 col-md-4">
                            <input
                              class="form-control pointer p-1"
                              id="appt-end-time"
                              type="time"
                              v-model="time.saturdayTo"
                              name="end-time"
                              value="00:00"
                            />
                          </div>
                        </div>
                        <div
                          class="hours-body-inner row align-items-center my-2"
                        >
                          <div
                            class="
                              col-12
                              text-start
                              py-1 py-lg-0
                              col-md-4
                              f-16
                              text-dark
                            "
                          >
                            <span>Sunday</span>
                          </div>
                          <div class="col-6 col-md-4">
                            <input
                              class="form-control pointer p-1"
                              id="appt-start-time"
                              type="time"
                              v-model="time.sundayFrom"
                              name="start-time"
                              value="00:00"
                            />
                          </div>
                          <div class="col-6 col-md-4">
                            <input
                              class="form-control pointer p-1"
                              id="appt-end-time"
                              type="time"
                              v-model="time.sundayTo"
                              name="end-time"
                              value="00:00"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="cta_submit button text-center">
                    <SubmitButton
                      :processing="processing"
                      type="submit"
                      class="
                        btn btn-primary
                        waves-effect waves-light
                        py-3
                        px-5
                        w-100
                      "
                      text="Update Listing"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
// import { UploadMedia, UpdateMedia } from "vue-media-upload";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Swal from "sweetalert2";
import AddressFields from "../AddressFields.vue";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Claim Business",
    meta: [
      {
        name: "approved",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    AddressFields,
    // UpdateMedia,
    Multiselect,
    VueUploadMultipleImage,
  },
  data() {
    return {
      processing: false,
      categoryOptions:[],
      categorySelected:[],
      selected: [],
      options: [],
      dishes: [{ image: null, 
        name: '', 
        price:'',
        type: '',
        id:null,
        previewImage: null }
      ],
      dish_media:[],
      videoError:false,
      title: "Edit Business",
      items: [
        {
          text: "Omaha",
        },
        {
          text: "Edit Business",
          active: true,
        },
      ],
      faq: [],
      time: {
        mondayFrom: null,
        mondayTo: null,
        tuesdayFrom: null,
        tuesdayTo: null,
        wednesdayFrom: null,
        wednesdayTo: null,
        thursdayFrom: null,
        thursdayTo: null,
        fridayFrom: null,
        fridayTo: null,
        saturdayFrom: null,
        saturdayTo: null,
        sundayFrom: null,
        sundayTo: null,
      },
      video_url: [],
      banner_url: null,
      banner: "",
      logo: "",
      video: [],
      categories: null,
      subCategories: null,
      users: null,
      address: {
        city: null,
        state: null,
        country: null,
        address: null,
        zipcode: null,
        latitude:null,
        longitude:null,
        place_id:null,
      },
      images: [],
      allFeature: [],
      feature: [],
      savedMdia: [],
      newUploadMedia: [],
      media: [],
      form: {
        availability_hours:true,
        city: null,
        state: null,
        country: null,
        address: null,
        place_id:null,
        title: null,
        email: null,
        mobile: null,
        website: null,
        racdting:null,
        ranking:null,
        category_id: [],
        user_id: null,
        overview: null,
        social: {
          facebook: null,
          instagram: null,
          twitter: null,
          google: null,
        },
        time: {
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null,
          saturday: null,
          sunday: null,
        },
      },
    };
  },
  mounted() {
    this.videoOnly();
    this.mediaOnly();
    this.singleList();
    this.allCategory();
    this.allUser();
    this.allFeatures();
  },
  computed:{
      getBannerImg(){
        return this.banner_url;
        },
      getDishes(){
        return this.dishes;
      }
  },
  methods: {
    allFeatures() {
      this.$axios
        .get("feature")
        .then((response) => {
          this.allFeature = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    videoOnly() {
      this.$axios
        .get("video/" + this.$route.params.id)
        .then((response) => {
          // response.data.forEach((element) => {
          //   this.video_url.push(element.media_path);
          // });

          this.video_url = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    mediaOnly() {
      this.$axios.get("update/" + this.$route.params.id).then((response) => {
        response.data.media.forEach((item) => {
          let media = {
            path: item.name,
            default: 1,
            highlight: 1,
          };
          this.images.push(media);
        });
        // this.images = media;
        console.log(this.images);
      });
    },
    userPersonalData(data) {
      this.form.email = data.email;
      this.form.title = data.title;
      this.form.mobile = data.mobile;
      this.form.website = data.website;
      this.form.overview = data.overview;
      this.form.availability_hours=data.availability_hours
      this.form.rating=data.rating
      this.form.ranking=data.ranking
    },
    singleList() {
      let self = this;
      this.$axios
        .get("single-business-listing/" + this.$route.params.id)
        .then((response) => {
          console.log(response,'-----response-----');
          // console.log(response.data.data.availability_hours);
          //sub category
          this.categorySelected = response.data.data.categories;
          // this.selected = response.data.data.sub_categories;
          //category section
          // let categories = [];
          // response.data.data.categories.forEach(element=>{
          //   categories.push(element.category);
          // })
          var uniq = {};
          var arrFiltered = this.categorySelected.filter(obj => !uniq[obj?.id] && (uniq[obj?.id] = true));
          this.categorySelected = arrFiltered;
          this.changeCategory();
          //Faq Section
          if(response.data.data?.faqs){

            response.data.data.faqs.forEach((element) => {
              let newObj = {};
              newObj.question = element.question;
              newObj.answer = element.answer;
              this.faq.push(newObj);
            });
          }
          console.log(this.faq,'-----categorySelected-----');

          //banner url
          this.banner_url = response.data.data.banner_url;
          //user Personal data
          this.userPersonalData(response.data.data);
          //user id
          this.form.user_id = response.data.data.user_id;
          //listing features
          response.data.data.features.forEach((element) => {
            this.feature.push(element.id);
          });
          // console.log(this.feature);
          // console.log(this.feature);
          //listing category & subcategory
          // this.form.sub_category_id = response.data.data.sub_categories[0].id;
          this.form.category_id = response.data.data.category_id;
          this.getAllSubCategories(this.form.category_id);
          //listing addresss
          self.address.address = response.data.data.address.address;
          this.address.country = response.data.data.address.country;
          this.address.city = response.data.data.address.city;
          this.address.state = response.data.data.address.state;
          this.address.zipcode = response.data.data.address.zipcode;
          this.address.latitude = response.data.data.address.latitude;
          this.address.longitude = response.data.data.address.longitude;
          // this.address.place_id = response.data.data.address.place_id;
          //listing social media
          response.data.data.social_media.forEach((element) => {
            self.form.social[element.social_media_type] =
              element.social_media_url;
          });
          //listing available time
          let time = response.data.data.listing_time;
          // console.log(time,"listing available time");
          this.time.mondayFrom = time[0].hours.split("-")[0];
          this.time.mondayTo = time[0].hours.split("-")[1];
          this.time.tuesdayFrom = time[1].hours.split("-")[0];
          this.time.tuesdayTo = time[1].hours.split("-")[1];
          this.time.wednesdayTo = time[2].hours.split("-")[0];
          this.time.wednesdayFrom = time[2].hours.split("-")[1];
          this.time.thursdayFrom = time[3].hours.split("-")[0];
          this.time.thursdayTo = time[3].hours.split("-")[1];
          this.time.fridayFrom = time[4].hours.split("-")[0];
          this.time.fridayTo = time[4].hours.split("-")[1];
          this.time.saturdayFrom = time[5].hours.split("-")[0];
          this.time.saturdayTo = time[5].hours.split("-")[1];
          this.time.sundayFrom = time[6].hours.split("-")[0];
          this.time.sundayTo = time[6].hours.split("-")[1];

          this.dishes = response.data.data.dishes;
          for (let index = 0; index < this.dishes.length; ++index) {
            console.log(this.dishes[index].media[0].media_path,);
            if(!this.dishes[index].previewImage)
          {
            this.dishes[index].previewImage = this.dishes[index].media[0].media_path;
          }
          }
          // response.data.data.dishes.forEach((element) => {
          //   self.form.social[element.social_media_type] =
          //     element.social_media_url;
          // });
          // this.dishes.previewImage
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    onFilebanner(e) {
      const file = e.target.files[0];
      this.banner = this.$refs.banner.files[0];
      // console.log(this.banner);
      this.banner_url = URL.createObjectURL(file);
    },
    selectMedia(e) {
      const file = e.target.files[0];
      // console.log(file);
    },
    submit() {
      this.processing = true;
      this.form.category_id = this.categorySelected.map(element => element.id);
      this.form.state = this.address.state;
      this.form.country = this.address.country;
      this.form.address = this.address.address;
      this.form.city = this.address.city;
      this.form.place_id = this.address.place_id;
      //Time range
      this.form.time.monday = this.time.mondayFrom + "-" + this.time.mondayTo;
      this.form.time.tuesday =
        this.time.tuesdayFrom + "-" + this.time.tuesdayTo;
      this.form.time.wednesday =
        this.time.wednesdayFrom + "-" + this.time.wednesdayTo;
      this.form.time.thursday =
        this.time.thursdayFrom + "-" + this.time.thursdayTo;
      this.form.time.friday = this.time.fridayFrom + "-" + this.time.fridayTo;
      this.form.time.saturday =
        this.time.saturdayFrom + "-" + this.time.saturdayTo;
      this.form.time.sunday = this.time.sundayFrom + "-" + this.time.sundayTo;
      let formData = new FormData();
      for (var i = 0; i < this.video.length; i++) {
        let file = this.video[i];
        formData.append("video[" + i + "]", file);
      }
      formData.append("banner", this.banner);
      formData.append("address", JSON.stringify(this.address));
      formData.append("form", JSON.stringify(this.form));
      formData.append("faq", JSON.stringify(this.faq));
      formData.append("feature", JSON.stringify(this.feature));
      formData.append("dishes", JSON.stringify(this.dishes));
      for (let i = 0; i < this.dishes.length; i++) {
          // if (this.dishes[i].image) {
          formData.append(`dish_media[${i}]`, this.dishes[i].image);
        // }
      }
      console.log(...formData);
      this.$axios
        .post("update-business-listing/" + this.$route.params.id, formData)
        .then((response) => {
          // console.log(response.data);
          this.triggerSwal(response.data.message, "success");
          this.processing = false;
          this.$router.push('/business/listing');
          // this.singleList()
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
          // console.log(error);
        });
    },
    allCategory() {
     
      this.$axios
        .get("categories")
        .then((response) => {
          this.categories = response.data.data;
          this.categoryOptions=response.data.data;
          // console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    changeCategory() {
      this.categorySelected.forEach(element=>{
        this.options=[];
        this.$axios
        .get("categories/" + element.id)
        .then((response) => {
          // this.subCategories = response.data.data;
          response.data.data.forEach(element=>{
            this.categoryOptions.push(element);
          })
        })
        .catch((error) => {
          console.log(error.response);
        });
      })
    },
    allUser() {
      this.$axios
        .get("all-users-for-list")
        .then((response) => {
          this.users = response.data.data;
          //   console.log(response.data.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    uploadLogo(e) {
      this.logo = e.target.files[0];
    },
    uploadVideo(e) {
      let video = e.target.files[0];
      if (video.size >= 50000000) {
        return this.videoError=true
      }
      this.videoError=false;
      let temp_video_url = URL.createObjectURL(video);
      const obj = {};
      obj.media_path = temp_video_url;
      obj.id = this.video_url.length + 1;
      this.video_url.push(obj);
      console.log(this.video_url);
      this.video.push(video);
    },
    uploadImageSuccess(formData, index, fileList) {
      formData.append("id", this.$route.params.id);
      var self = this;
      self.$axios
        .post("/update-images", formData)
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
          // this.handleErrorResponse(error.response, "error");
        });
    },
    beforeRemove(index, done, fileList) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const lastSegment = this.images[index].path.split("/").pop();
          done();
          this.$axios
            .post("delete-images", {
              fileName: "/storage/listing_images/" + lastSegment,
              id: this.$route.params.id,
            })
            .then((response) => {
              // console.log(response.data);
            })
            .catch((error) => {
              this.handleErrorResponse(error.response, "error");
            });
        }
      });
    },
    getAllSubCategories(id) {
      // alert(id);
      this.$axios
        .get("all-subCategories/" + id)
        .then((response) => {})
        .catch((error) => {
          console.log(error.response);
        });
    },
    removeVideo(index, id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var completeObject = this.video_url[index];
          if (completeObject.media_path.indexOf("videos") > -1) {
            var parts = completeObject.media_path.split("/");
            var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash
            this.$axios
              .post("delete-images", {
                fileName: "/storage/videos/" + lastSegment,
                id: this.$route.params.id,
              })
              .then((response) => {
                console.log(response.data);
                this.video_url.splice(index, 1);
              });
            // });
          } else {
            this.video_url.splice(index, 1);
          }
        }
      });

      // console.log(lastSegment);
    },
    addMoreDish() {
      this.dishes.push({ image: null, name: '', type: '', previewImage: null,id:null});
    },
    removeDishBox(index,id) {
      if(id)
      {
        Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$axios
        .get("delete/vendor/dish/" + id)
        .then((response) => {
          if(response.data.success)
        {
          this.dishes.splice(index, 1);
        }
        });
        }
      });
      }
      this.dishes.splice(index, 1);
    },
    onImageChange(event, index) {
      console.log(index,'---index');
      
      const file = event.target.files[0];
      this.dishes[index].image = file;
      if (file) {
      let dishImage =  document.getElementById(`dish-${index}`)
      dishImage.classList.add('d-block');
      const previewImageUrl = URL.createObjectURL(file);
      dishImage.src = previewImageUrl;
      }
    },
    removePreviewImage(index) {
      this.getDishes[index].previewImage = null;
    },
    removeFaqBox(index) {
      this.faq.splice(index, 1);
    },
    addMore() {
      this.faq.push({
        question: null,
        answer: null,
      });
    },
    hoursStatus(){
      console.log('test');
    }
  },
  watch: {
    // Watch the entire `dishes` array for changes
    dishes: {
    handler(newDishes) {
      // Simply replace the old dishes array with the new one
      this.dishes = newDishes.map((dish) => {
        return { ...dish }; // Spread the new dish properties into the new array
      });
    },
    deep: true // Ensures changes inside array elements trigger the watcher
  }
  }
};
</script>